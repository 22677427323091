import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/joy/Typography';
import Option from '@mui/joy/Option';
import Textarea from '@mui/joy/Textarea';
import { FileUploader } from "react-drag-drop-files";
import NativeSelect from '@mui/material/NativeSelect';
import { useRef } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';

export default function ModalMenu(props) {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [nom, setNom] = React.useState(null);
    const [tipus, setTipus] = React.useState(null);
    const [descripcio, setDescripcio] = React.useState(null);
    const [preu, setPreu] = React.useState(null);
    const [primers, setPrimers] = React.useState(null);
    const [segons, setSegons] = React.useState(null);
    const [postres, setPostres] = React.useState(null);
    const [plats, setPlats] = React.useState(null);
    const dataMenu = useRef(null);

    useEffect(() => {
     
        getPlats();
    }, []);



    const getPlats = () => {
        fetch(process.env.REACT_APP_API_URL + "/plats", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setPlats(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    return (
        <React.Fragment>
            {plats && <>
            <Button
                variant="outlined"
                color="neutral"
                startDecorator={<Add />}
                onClick={() => setOpen(true)}
            >
                Agrega nou Menu
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog className="modalPlat"
                    aria-labelledby="basic-modal-dialog-title"
                    aria-describedby="basic-modal-dialog-description"
                    sx={{ maxWidth: 500 }}
                >
                    <Typography id="basic-modal-dialog-title" component="h2">
                        Crear un nou menu
                    </Typography>
                    <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                        Omple els camps per crear un nou menu
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();

                            let platos = [];

                            platos.push(...primers.map((primer) => {
                                return {
                                    "tipo": "Primer plat",
                                    "id_plato": primer.value,
                                    "cantidad_minima": 1
                                }
                            }));

                            platos.push(...segons.map((segon) => {
                                return {
                                    "tipo": "Segon plat",
                                    "id_plato": segon.value,
                                    "cantidad_minima": 1
                                }
                            }));

                            platos.push(...postres.map((postre) => {
                                return {
                                    "tipo": "Postre",
                                    "id_plato": postre.value,
                                    "cantidad_minima": 1
                                }
                            }));


                            let menu = {
                                nombre: nom,
                                precio: 11.5,
                                fecha: dataMenu.current.value,
                                platos: JSON.stringify(platos)


                            }
                            const menuString = JSON.stringify(menu);
                            console.log(menuString);
                            
                            fetch(process.env.REACT_APP_API_URL + "/crearmenu", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                },
                                body: menuString
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    props.getMenus();
                                }
                                )
                                .catch((error) => {
                                    console.log(error);
                                }
                                );
                    





                                 setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Nom del menu</FormLabel>
                                <Input onChange={(e) => setNom(e.target.value)} autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Data del menú</FormLabel>
                                <input className="placeholderCrearMenu" ref={dataMenu} type="date" placeholder="Data del menu" />


                            </FormControl>
                            <FormControl>
                                <FormLabel>Primers plats</FormLabel>
                                <Select className="selectorPlats"
                                    value={primers}
                                    onChange={setPrimers}
                                    options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                    isMulti
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Segons plats</FormLabel>
                                <Select className="selectorPlats"
                                    value={segons}
                                    onChange={setSegons}
                                    options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                    isMulti
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Postres</FormLabel>
                                <Select className="selectorPlats"
                                    value={postres}
                                    onChange={setPostres}
                                    options={plats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                    isMulti
                                />
                            </FormControl>
                            <Button type="submit">Crear menu</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
           
            </>}
        </React.Fragment>
    );
}