
import LeftMenu from './components/menu.js';
import { useEffect } from 'react';
import { useState } from 'react';
import Plat from './components/plat.js';
import ModalMenu from './components/addMenuModal.js';
import MenuPlats from './components/menuPlats.js';
import CachedIcon from '@mui/icons-material/Cached';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';


const Menus = () => {
    const [menus, setMenus] = useState([]);
    const [pdfUrl, setPdfUrl] = useState("");
    const [alert, setAlert] = useState(false);

    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())

            .then((data) => {
                if (data === true) {
                    getMenus();
                    getPDF();
                }
                if (data === false) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );



    }, []);

    const getPDF = () => {
        fetch(process.env.REACT_APP_API_URL + "/getpdf", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPdfUrl(data[0].url);

            })
            .catch((error) => {
                console.log(error);
            });
    };




    const getMenus = () => {
        fetch(process.env.REACT_APP_API_URL + "/menus", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setMenus(data);

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const enviarPDF = () => {
        fetch(process.env.REACT_APP_API_URL + "/modificarpdf", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({ url: pdfUrl }),
        })
            .then((response) => response.json())
            .then((data) => {
                getPDF();
                setAlert(true);
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );

    };




    return (
        <div className="menu-container" onClick={() => setAlert(false)}>

            <LeftMenu />
            <div className='content'>
                <h1>Menus</h1>
                {alert &&
                    <Stack sx={{ width: '50%' }} spacing={2}>
                        <Alert severity="success">
                            <AlertTitle>PDF Modificat correctament!</AlertTitle>
                        </Alert>
                    </Stack>
                }
                    <div className='inputPDF'>
                        <p>Afageix la URL del menú setmanal</p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input placeholder="URL" value={pdfUrl && pdfUrl} onChange={(e) => setPdfUrl(e.target.value)} />
                            <CachedIcon className="sendPDF" onClick={enviarPDF} style={{ marginLeft: "10px" }} />
                        </div>
                    </div>
                
                <ModalMenu getMenus={getMenus} />
                <div className='gridPlats'>
                    {menus &&
                        menus.map((menu) => (

                            <MenuPlats getMenus={getMenus} menuInfo={menu} />

                        ))
                    }
                </div>
            </div>

        </div>
    )
}
export default Menus