import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { CartProvider, useCart } from "react-use-cart";
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

const Checkout = () => {

    //get all ids from cart and quantity
    const { items } = useCart();
    const { metadata } = useCart();
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [menus, setMenus] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [preuTransport, setPreuTransport] = useState(0);
    const [esEnviament, setEsEnviament] = useState(null);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const nom = useRef();
    const telefon = useRef();
    const adreca = useRef();
    const codiPostal = useRef();
    const email = useRef();
    const horaEntrega = useRef();
    const observacions = useRef();
    const politicaPrivacitat = useRef();
    const [formaPagament, setFormaPagament] = useState(1);
    const [timer, setTimer] = useState(5);
    const [comple, setComple] = useState(false);
    const [redsysSignature, setRedsysSignature] = useState(null);
    const [redsysDs_MerchantParameters, setRedsysDs_MerchantParameters] = useState(null);
    const [redsysAction, setRedsysAction] = useState(null);
    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [producteIndividual, setProducteIndividual] = useState(false);
    const { emptyCart } = useCart();
    const [shippingType, setShippingType] = useState(metadata.shippingType);
    const [suplements, setSuplements] = useState([]);


    useEffect(() => {
        //get all ids from cart and quantity
        let cart = [];


        if (shippingType === 0) {
            setPreuTransport(0);
            setEsEnviament(false);
        } else {
            setPreuTransport(2);
            setEsEnviament(true);
        }
        getSuplements();

        /*
          
         if (event.target.value === 2) {
             setPreuTransport(0);
             setEsEnviament(false);
         } else {
             setPreuTransport(2);
             setEsEnviament(true);
         }
         */


        items.forEach(item => {
            let plat = {
                id: item.id,
                quantity: item.quantity
            }
            cart.push(plat);
        });
        setCart(cart);
        checkout(cart);
    }, [items]);

    useEffect(() => {
        if (comple) {
            if (timer > 0) {
                setTimeout(() => {
                    setTimer(timer - 1);
                }, 1000);
            } else {
                window.location.href = "/";
            }
        }
    }, [timer, comple]);

    useEffect(() => {
        if (formRef.current && redsysDs_MerchantParameters && redsysSignature) {
            formRef.current.submit();
        }
    }, [redsysDs_MerchantParameters, redsysSignature]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (type) => {

        if (type === 0) {
            setOpen(false);

        } else {
            if (observacions.current.value === "") {
                setOpen(false);
                return;
            }

            if (formaPagament === 2) {
                finalitzarRedsys();
                setOpen(false);
            } else {
                finalitzar();
                setOpen(false);
            }

        }


    };
    const getSuplements = () => {
        fetch(process.env.REACT_APP_API_URL + "/getsuplements", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //añade a data el atributo quantity
                data.forEach(item => {
                    item.quantity = 0;
                });

                setSuplements(data);
            });
    }


    const handleChangeEnv = (event) => {



        if (event.target.value === 2) {
            setPreuTransport(2);
            setEsEnviament(true);
        } else {
            setPreuTransport(0);
            setEsEnviament(false);
        }
        setShippingType(event.target.value);

    };



    const checkout = (data) => {
        //send cart to backend
        fetch(process.env.REACT_APP_API_URL + "/checkout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                setTotalPrice(data.totalPrice);

                if (data.menus[0][0].menu == 0) {
                    setProducteIndividual(true);
                }
                setMenus(data.menus);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleFinalitzar = () => {
        if (producteIndividual) {
            handleClickOpen();
        } else {
            if (formaPagament === 2) {
                finalitzarRedsys();
            } else {
                finalitzar();
            }
        }
    }

    const finalitzarRedsys = () => {
        let form;


        if (esEnviament === null) {
            setError("Has de seleccionar com vols recollir la teva comanda");
            return;
        }

        if (esEnviament) {
            if (nom.current.value === "" || telefon.current.value === "" || adreca.current.value === "" || codiPostal.current.value === "" || email.current.value === "" || horaEntrega.current.value === "" || politicaPrivacitat.current.value === "") {
                setError("No has omplert tots els camps obligatoris");
                return;
            }
            form = {
                nom: nom.current.value,
                telefon: telefon.current.value,
                adreca: adreca.current.value,
                codiPostal: codiPostal.current.value,
                email: email.current.value,
                horaEntrega: horaEntrega.current.value,
                observacions: observacions.current.value,
                formaPagament: formaPagament,
            }

        } else {
            if (nom.current.value === "" || telefon.current.value === "" || email.current.value === "" || politicaPrivacitat.current.value === "") {
                setError("No has omplert tots els camps obligatoris");
                return;
            }
            form = {
                nom: nom.current.value,
                telefon: telefon.current.value,
                email: email.current.value,
                formaPagament: formaPagament,
                observacions: observacions.current.value,
            }
        }

        //console.log(form);

        let data = {
            cart: cart,
            form: form,
            esEnviament: esEnviament,
            suplements: suplements,
        }


        //send cart to backend
        fetch(process.env.REACT_APP_API_URL + "/finalitzaredsys", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setRedsysSignature(data.form.DS_MERCHANT_SIGNATURE);
                setRedsysDs_MerchantParameters(data.form.DS_MERCHANT_APARAMS);
                setRedsysAction(data.form.action);
                emptyCart();
                //  setComple(true);

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const finalitzar = () => {


        let form;
        //validar formulario si no esta rellenado

        if (esEnviament === null) {
            setError("Has de seleccionar com vols recollir la teva comanda");
            return;
        }
        if (esEnviament) {
            if (nom.current.value === "" || telefon.current.value === "" || adreca.current.value === "" || codiPostal.current.value === "" || email.current.value === "" || horaEntrega.current.value === "" || politicaPrivacitat.current.value === "") {
                setError("No has omplert tots els camps obligatoris");
                return;
            }
            form = {
                nom: nom.current.value,
                telefon: telefon.current.value,
                adreca: adreca.current.value,
                codiPostal: codiPostal.current.value,
                email: email.current.value,
                horaEntrega: horaEntrega.current.value,
                observacions: observacions.current.value,
                formaPagament: formaPagament,
            }

        } else {
            if (nom.current.value === "" || telefon.current.value === "" || email.current.value === "" || politicaPrivacitat.current.value === "") {
                setError("No has omplert tots els camps obligatoris");
                return;
            }
            form = {
                nom: nom.current.value,
                telefon: telefon.current.value,
                email: email.current.value,
                formaPagament: formaPagament,
                observacions: observacions.current.value,
            }
        }

        //console.log(form);

        let data = {
            cart: cart,
            form: form,
            esEnviament: esEnviament,
            suplements: suplements,
        }


        //send cart to backend
        fetch(process.env.REACT_APP_API_URL + "/finalitza", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.type === "succes") {
                    setSuccess("La teva comanda s'ha realitzat correctament. Rebreu un correu amb la confirmació de la comanda. Gràcies per confiar en nosaltres.");
                    setCart([]);
                    setMenus([]);

                    setTotalPrice(0);
                    setComple(true);
                    emptyCart();

                }
                if (data.type === "error") {
                    setError("Hi ha hagut un error al realitzar la comanda. Si us plau torna-ho a provar. Gràcies.");
                }

            }
            )
            .catch((error) => {

            }
            );

    }
    const decrementSuplement = (item) => {
        let quantity = item.quantity;
        if (quantity > 0) {
            quantity--;
            item.quantity = quantity;
            setTotalPrice(totalPrice - item.precio);
            setCart([...cart]);
        }
    }
    const incrementSuplement = (item) => {
        let quantity = item.quantity;
        quantity++;
        item.quantity = quantity;
        setTotalPrice(totalPrice + item.precio);
        setCart([...cart]);
    }


    return (
        <>
            {
                redsysAction &&
                <>
                    <form ref={formRef} id="redsysForm" action={redsysAction} method="post">
                        <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                        <input type="hidden" name="Ds_MerchantParameters" value={redsysDs_MerchantParameters} />
                        <input type="hidden" name="Ds_Signature" value={redsysSignature} />
                        <button type="submit">submit</button>
                    </form>
                </>
            }

            <div className="big-main-check">

                <div className="main-checkout" onClick={() => { if (error) { setError(false) } }}>
                    <div className="checkout-cart firstCH">
                        <div className="checkout-cart-container">
                            <h1 className="checkout-title dadesP">Resum comanda</h1>
                            <div className="checkout-cart-container-in">
                                <span className="checkout-deliv-title">Suplements</span>
                                <p className="listamenu nomg">Selecciona si vols algun suplement</p>
                                {suplements &&
                                    suplements.map((item, index) => (
                                        <div className="checkout-cart-suplement">
                                            <span className="checkout-name">{item.nombre}<span className="minim">+ {item.precio}€</span></span>
                                            <div className="checkout-cart-suplement-counter   ">
                                                <button className="checkout-quantity" onClick={() => { decrementSuplement(item) }}>-</button>
                                                <span className="checkout-quantity">{item.quantity}</span>
                                                <button className="checkout-quantity" onClick={() => { incrementSuplement(item) }}>+</button>
                                            </div>
                                        </div>
                                    ))}

                            </div>
                            <div className="checkout-cart-container-in">
                                {menus &&
                                    menus.map((menu, index) => (
                                        <>
                                            {menu[0].menu != 0 ? (
                                                <p className="listamenu">Menu {menu[0].fecha}</p>
                                            ) : (
                                                <p className="listamenu">Plats de carta</p>
                                            )}

                                            {menu !== null &&
                                                menu
                                                    .sort((a, b) => {
                                                        const order = ["Primer plat", "Segon plat", "Postre"];
                                                        return order.indexOf(a.tipo) - order.indexOf(b.tipo);
                                                    })
                                                    .map((item, index) => (
                                                        <div className="checkout-cart-item">
                                                            <span className="checkout-quantity">{item.quantity}</span>
                                                            <span className="checkout-name">{item.name}</span>
                                                            <span className="checkout-price">{item.price}€</span>
                                                        </div>
                                                    ))}
                                        </>
                                    ))}


                            </div>
                            <div className="checkout-deliv">
                                <span className="checkout-deliv-title">Preu transport</span>
                                <span className="checkout-deliv-price">{preuTransport}€</span>
                            </div>
                            <div className="checkout-total">
                                <span className="checkout-total-title">Total</span>
                                <span className="checkout-total-price">{(totalPrice + preuTransport).toFixed(2)}€</span>
                            </div>
                            <Link to="/">
                                <div className="boxBtn">

                                    <div className="checkout-button">
                                        <IconButton aria-label="delete" className="btn-back" hinputRef="/cart">
                                            <ArrowBackRoundedIcon />
                                        </IconButton>
                                        <span> Tornar enrere</span>
                                    </div>


                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="checkout-form">
                        <div className="check-formulario">
                            <div className="checkout-cart-container">
                                <h1 className="checkout-title dadesP">Dades personals</h1>
                            </div>


                            <div className="check-af">
                                {
                                    error && <Alert severity="error">{error}</Alert>
                                }

                                {
                                    success && <><Alert severity="success">{success}</Alert>
                                        <br></br>
                                        <Alert severity="info">Seras redireccionat en {timer}</Alert>
                                    </>
                                }
                                {
                                    !success && <>

                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 1, width: '30%' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >

                                            <div>Informació de contacte</div>
                                            <TextField inputRef={nom} id="nom" label="Nom i cognoms" variant="outlined" />
                                            <TextField inputRef={email} id="email" label="Email" variant="outlined" />
                                            <TextField inputRef={telefon} id="tel" label="Telèfon" variant="outlined" />
                                            <TextField inputRef={observacions} className="observ" placeholder="Observacions" />
                                            <div className="centerM">
                                                <FormControl fullWidth className="askMed">
                                                    <InputLabel id="env">Enviament</InputLabel>
                                                    <Select
                                                        labelId="env"
                                                        id="select"
                                                        label="Vols rebre la teva comanda a casa o vols venir a recollir-la?"
                                                        onChange={handleChangeEnv}
                                                        value={shippingType}
                                                    >
                                                        {
                                                            /*
                                                            <MenuItem value={2}>Vull rebre la meva comanda a casa</MenuItem>
                                                            */
                                                        }
                                                        <MenuItem value={0}>Recolliré la comanda al Racó</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {
                                                esEnviament &&
                                                <>
                                                    <TextField inputRef={adreca} id="dir" label="Adreça" variant="outlined" />
                                                    <TextField inputRef={codiPostal} id="codi" label="Codi Postal" variant="outlined" />
                                                    <TextField inputRef={horaEntrega} id="dia-hora" label="Dia" variant="outlined" />


                                                </>
                                            }
                                        </Box>
                                        <div className="title100  ">Mètode de pagament</div>

                                        <div className="boxTypes ">

                                            <div onClick={() => setFormaPagament(1)} className={formaPagament === 1 ? "boxType selectedType" : "boxType"}>
                                                <div className="boxTypeImg">
                                                    <img src="https://cdn-icons-png.flaticon.com/512/1409/1409794.png" alt="efectiu" />
                                                </div>
                                                <div className="boxTypeTitle">Pagar a l'entrega</div>

                                            </div>
                                            {

                                                <div onClick={() => setFormaPagament(2)} className={formaPagament === 2 ? "boxType selectedType" : "boxType"}>
                                                    <div className="boxTypeImg">
                                                        <img src="https://cdn-icons-png.flaticon.com/512/5700/5700956.png" alt="tarjeta" />
                                                    </div>
                                                    <div className="boxTypeTitle">Targeta</div>

                                                </div>


                                            }
                                            {
                                                /*
                                                <div className="boxType disabledType" >
                                                    <div className="boxTypeImg">
                                                        <img src="https://cdn-icons-png.flaticon.com/512/5700/5700956.png" alt="tarjeta" />
                                                    </div>
                                                    <div className="boxTypeTitle">Targeta</div>

                                                </div>
*/
                                            }
                                            <div onClick={() => setFormaPagament(3)} className={formaPagament === 3 ? "boxType selectedType" : "boxType"}>
                                                <div className="boxTypeImg">
                                                    <img src="https://lamafia.es/wp-content/uploads/2017/02/Cheque-Gourmet.jpg" alt="xec restaurant" />
                                                </div>
                                                <div className="boxTypeTitle">Xec gourmet</div>

                                            </div>
                                            <div onClick={() => setFormaPagament(4)} className={formaPagament === 4 ? "boxType selectedType" : "boxType"}>
                                                <div className="boxTypeImg">
                                                    <img src="https://www.masfamilia.org/wp-content/uploads/2019/10/SODEXO-PASS.png" alt="sodexo" />
                                                </div>
                                                <div className="boxTypeTitle">Sodexo</div>


                                            </div>
                                            <div onClick={() => setFormaPagament(5)} className={formaPagament === 5 ? "boxType selectedType" : "boxType"}>
                                                <div className="boxTypeImg">
                                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBqweQeNFeUt64NjmbCvtXit1JwJmDNyWhNQ&usqp=CAU" alt="ticket restaurant" />
                                                </div>
                                                <div className="boxTypeTitle">Ticket Restaurant</div>
                                            </div>
                                        </div>
                                        <FormGroup>
                                            <FormControlLabel inputRef={politicaPrivacitat} control={<Checkbox defaultChecked />} label="He llegit i accepto la política de privacitat" />
                                        </FormGroup>
                                        <p className="form-info">Comandes online fins a les 11:30h i telefònicament fins a les 14:30h.</p>

                                        <button onClick={() => handleFinalitzar()} className="btn-checkout">Finalitzar Comanda</button>

                                        <Stack sx={{ width: '100%', marginTop: "20px" }} spacing={2}>


                                        </Stack>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={() => handleClose(0)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Per quan son els plats?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Hem detectat que tens plats de carta a la teva comanda, abans de continuar explicans a la casella observacions per quin dia són aquests plats.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(0)}>Enrere</Button>
                    <Button onClick={() => handleClose(1)} autoFocus>
                        Finalitzar comanda
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

};
export default Checkout;