import MenuIconSideNavExample from './components/menu.js';
import LeftMenu from './components/menu.js';
import OrderTable from './components/orderTable.js';
import { useEffect } from 'react';

const Comandes = () => {

    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + "/comprobarAdmin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: localStorage.getItem("token"), id: localStorage.getItem("id") }),
        })
            .then((response) => response.json())
           
            .then((data) => {
                if(data === true){
                }
                if(data === false){
                    localStorage.removeItem("token");
                    localStorage.removeItem("id");
                    window.location.href = "/login";
                }
            }
            );
        
      
        
    }, []);


    return (
        <div className="menu-container">
            <LeftMenu />
            <div className='content'>
                <h1>Comandes</h1>
                <OrderTable />
            </div>

        </div>
    )
}
export default Comandes