import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Select from 'react-select';
import { useEffect } from 'react';

export default function EditarModalPlat(props) {
    const [open, setOpen] = React.useState(true);
    const [menuInfo, setMenuInfo] = React.useState(null);
    const [allplats, setAllPlats] = React.useState(null);
    const [primers, setPrimers] = React.useState([]);
    const [segons, setSegons] = React.useState([]);
    const [postres, setPostres] = React.useState([]);


    useEffect(() => {


        const platos = JSON.parse(props.menuInfo.platos);
      

        async function fetchPlato(idPlato) {
            const response = await fetch(process.env.REACT_APP_API_URL + "/plat/" + idPlato, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            return data;
        }

        async function loadPlatos() {
            for (let plato of platos) {
                const data = await fetchPlato(plato.id_plato);
                if (plato.tipo === 'Primer plat') {
                    setPrimers(primers => {
                        if (primers.some(p => p.id === data.id)) {
                            return primers;
                        } else {
                            return [...primers, data];
                        }
                    });
                } else if (plato.tipo === 'Segon plat') {
                    setSegons(segons => {
                        if (segons.some(p => p.id === data.id)) {
                            return segons;
                        } else {
                            return [...segons, data];
                        }
                    });
                } else if (plato.tipo === 'Postre') {
                    setPostres(postres => {
                        if (postres.some(p => p.id === data.id)) {
                            return postres;
                        } else {
                            return [...postres, data];
                        }
                    });
                }
            }
        }

        loadPlatos().catch(error => {
            console.log(error);
        });



        setMenuInfo(props.menuInfo);

        fetch(process.env.REACT_APP_API_URL + "/allplats", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {

                setAllPlats(data);
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );

    }, []);


    return (
        <React.Fragment>
            {menuInfo && (
                <Modal open={open} onClose={props.handleClose}>
                    <ModalDialog className="modalPlat"
                        aria-labelledby="basic-modal-dialog-title"
                        aria-describedby="basic-modal-dialog-description"
                        sx={{
                            maxWidth: 500,
                            overflowY: 'auto',  // Agregamos scroll vertical si el contenido excede la altura del modal
                            maxHeight: 'calc(100vh - 100px)'  // Limitamos la altura máxima del modal para evitar que se extienda más allá del viewport
                        }}
                    >
                        <Typography id="basic-modal-dialog-title" component="h2">
                            Editar menu
                        </Typography>
                        <Typography id="basic-modal-dialog-description" textColor="text.tertiary">
                            Editar el menu del {menuInfo.fecha}
                        </Typography>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                let platos = [];
                                platos.push(...primers.map((primer) => {
                                    return {
                                        "tipo": "Primer plat",
                                        "id_plato": primer.id,
                                        "cantidad_minima": 1
                                    }
                                }));

                                platos.push(...segons.map((segon) => {
                                    return {
                                        "tipo": "Segon plat",
                                        "id_plato": segon.id,
                                        "cantidad_minima": 1
                                    }
                                }));

                                platos.push(...postres.map((postre) => {
                                    return {
                                        "tipo": "Postre",
                                        "id_plato": postre.id,
                                        "cantidad_minima": 1
                                    }
                                }));

                                let menu = {
                                    id : menuInfo.id,
                                    nombre: menuInfo.nombre,
                                    precio: 11.5,
                                    fecha: menuInfo.fecha,
                                    platos: JSON.stringify(platos)
                                }

                                fetch(process.env.REACT_APP_API_URL + "/modificarmenu", {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                    },
                                    body: JSON.stringify(menu),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        console.log(data);
                                        props.handleClose();
                                        props.handleUpdate();
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    }
                                    );


                            }}
                        >
                            <Stack spacing={2}>
                                <FormControl>
                                    <FormLabel> Nom</FormLabel>
                                    <Input onChange={(e) => setMenuInfo(menuInfo => ({ ...menuInfo, nombre: e.target.value }))} value={menuInfo.nombre} autoFocus required />
                                    <Typography textColor="text.tertiary" style={{ marginTop: 20 }}>Data del menu</Typography>
                                    <input className="placeholderCrearMenu"  type="date" value={menuInfo.fecha} onChange={(e) => setMenuInfo(menuInfo => ({ ...menuInfo, fecha: e.target.value }))} />
                                </FormControl>
                                {allplats && (
                                    <>
                                        <FormControl>
                                            <FormLabel>Primers Plats</FormLabel>

                                            <Select
                                                className="selectorPlats"
                                                value={primers.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedPlats = selectedOptions.map((option) => {
                                                        const plat = allplats.find((p) => p.id === option.value);
                                                        return { ...plat, tipo: 'Primer plat' };
                                                    });
                                                    setPrimers(selectedPlats);
                                                }}
                                                options={allplats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Segons Plats</FormLabel>

                                            <Select
                                                className="selectorPlats"
                                                value={segons.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedPlats = selectedOptions.map((option) => {
                                                        const plat = allplats.find((p) => p.id === option.value);
                                                        return { ...plat, tipo: 'Segon plat' };
                                                    });
                                                    setSegons(selectedPlats);
                                                }}
                                                options={allplats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Postres</FormLabel>

                                            <Select
                                                className="selectorPlats"
                                                value={postres.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedPlats = selectedOptions.map((option) => {
                                                        const plat = allplats.find((p) => p.id === option.value);
                                                        return { ...plat, tipo: 'Postre' };
                                                    });
                                                    setPostres(selectedPlats);
                                                }}
                                                options={allplats.map((plat) => ({ value: plat.id, label: plat.nombre }))}
                                                isMulti
                                            />
                                        </FormControl>
                                    </>
                                )}


                                <Button type="submit">Modificar menu</Button>
                            </Stack>
                        </form> 
                    </ModalDialog>
                </Modal>
            )}
        </React.Fragment>
    );
}