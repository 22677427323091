import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Link} from 'react-router-dom';
const PagamentOk = () => {
    let { id } = useParams();

    useEffect(() => {

        //borra los productos del carrit

        /*

        fetch(process.env.REACT_APP_API_URL + "/redsysok", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ paymentId: id }),
        })
            .then((response) => response.json())
            .then((data) => {
              
            }
            );
*/

    }, []);

    return (
        <div className="bgBox">
            <div className='boxMiddle'>
                <CheckCircleIcon style={{ fontSize: 100, color: "#82d982f0" }} />
                <h1 className='okTitle'>Pagament realitzat correctament!</h1>
                <p className='okSub'>Número de transacció:<b></b> {id}</p>
                <div className='okSubBox'>
                    <p className='okSub'>En breu rebràs un correu electrònic amb la teva comanda. si tens cap inconvenient, pots contactar amb nosaltres al <a href='tel:938981101'>938 98 11 01</a></p>
                    

                </div>
                <Link to={"/"} ><button className='okButton'>Tornar a la botiga</button></Link>
            </div>

        </div>
    );
};
export default PagamentOk;