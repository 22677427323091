import Tienda from './tienda';
import Producto from './producto';
import React, { useContext } from "react";
import Cart from './cart';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { CartProvider, useCart } from "react-use-cart";
import Checkout from './checkout';
import './menu.css';
import Administrar from './administrar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Phone from '@mui/icons-material/Phone';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import useEffect from 'react';
import PagamentOk from './pagamentok';
import PagamentKo from './pagamentko';
import Login from './login';
import Comandes from './comades';
import Plats from './plats';
import Menus from './menus';
import Sortejos from './sortejos';
import PersonIcon from '@mui/icons-material/Person';
import Compte from './compte';
import Carta from './carta';
import Suplements from './suplements';
import Steps from './step';
import Opinions from './opinions';



function App() {


  const navigate = useNavigate();

  const [value, setValue] = React.useState('botiga');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // haz que sin newValue sea igual a home te lleve a la tienda pero si estas en / no te lleve a la tienda
    if (newValue === "botiga" && window.location.pathname !== "/") {
      navigate('/');

    }
    if (newValue === "telefon") {
      window.location.href = "tel:938 98 11 01";
    }
    if (newValue === "compte") {
      navigate('/compte');
    }

  };


  const location = useLocation();
  return (
    <CartProvider>
      <div className="App">


        <Routes key={location.pathname}>
          <Route path="/" element={<Producto />} />
          <Route path="/redsys/pagook/:id" element={<PagamentOk />} />
          <Route path="/redsys/pagoko/:id" element={<PagamentKo />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path='/administrar' element={<Administrar />} />
          <Route path='/login' element={<Login />} />
          <Route path='/admin/comandes' element={<Comandes />} />
          <Route path='/admin/plats' element={<Plats />} />
          <Route path='/admin/menus' element={<Menus />} />
          <Route path='/admin/sortejos' element={<Sortejos />} />
          <Route path='/admin/carta' element={<Carta />} />
          <Route path='/admin/suplements' element={<Suplements />} />
          <Route path='*' element={<Producto />} />
          <Route path='/admin/opinions' element={<Opinions />} />
          <Route path='/compte' element={<Compte />} />
          <Route path='/opinans' element={ <div className='main'><Steps type="0"/></div>} />
          <Route path='/admin/opinio/:id' element={ <div className='main'><Steps type="1" /></div>} />
        </Routes>
        {location.pathname !== '/opinans' && location.pathname !== '/checkout' && location.pathname !== '/administrar' && location.pathname !== '/login' && !isMobile && !location.pathname.startsWith('/redsys') && !location.pathname.startsWith('/admin') ? <Cart /> : null}
        {location.pathname !== '/opinans' && location.pathname !== '/checkout' && isMobile && value === "cistella" && !location.pathname.startsWith('/redsys') ? <Cart /> : null}
        



      </div>
      {isMobile &&
        <BottomNavigation sx={{ width: 500 }} value={value} onChange={handleChange}>
          <BottomNavigationAction
            label="Botiga"
            value="botiga"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            label="Cistella"
            value="cistella"
            icon={<LocalMallIcon />}
          />

{ 
/*
<BottomNavigationAction
            label="Compte"
            value="compte"
            icon={<PersonIcon />}
          />
*/
}
         
          <BottomNavigationAction
            label="Telèfon"
            value="telefon"
            icon={<Phone />}
          />

        </BottomNavigation>
      }
    </CartProvider>
  );
}

export default App;